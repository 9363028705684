import { default as attach0MoUUgws9kMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/agreements/[id]/drivers/attach.vue?macro=true";
import { default as indexp9ghZDTcHnMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/agreements/[id]/drivers/index.vue?macro=true";
import { default as editdUy4jCKTGAMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/agreements/[id]/edit.vue?macro=true";
import { default as indexYfm7WcnzQLMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/agreements/[id]/index.vue?macro=true";
import { default as createGCh35b4F1OMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/agreements/create.vue?macro=true";
import { default as index14UpqbIYvYMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/agreements/index.vue?macro=true";
import { default as dashboard76aFlOFRSxMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/dashboard.vue?macro=true";
import { default as editVtqzvEHuwoMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/[id]/edit.vue?macro=true";
import { default as indexw6VN6wRfIQMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/[id]/index.vue?macro=true";
import { default as createj2XTfrhGlmMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/create.vue?macro=true";
import { default as indexKfbMPzF49vMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/index.vue?macro=true";
import { default as editzTtGnUNzzMMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/types/[id]/edit.vue?macro=true";
import { default as indexCf5jOIqpTHMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/types/[id]/index.vue?macro=true";
import { default as createSMNwTjESIZMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/types/create.vue?macro=true";
import { default as indexuFCQazNyd5Meta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/types/index.vue?macro=true";
import { default as editmkZumNiCUAMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/vehicle-changes/[id]/edit.vue?macro=true";
import { default as index6IcLbKWX9oMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/vehicle-changes/[id]/index.vue?macro=true";
import { default as indexx2M388QkIvMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/vehicle-changes/index.vue?macro=true";
import { default as editzVdaUHcz0lMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/[id]/edit.vue?macro=true";
import { default as index3F7HEkxhL0Meta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/[id]/index.vue?macro=true";
import { default as _91id_93zay7EtI46MMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/bookings/[id].vue?macro=true";
import { default as indexEqOXYIiqdiMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/bookings/index.vue?macro=true";
import { default as _91id_93dy3Pn2Q9c5Meta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/earnings/[id].vue?macro=true";
import { default as indexVRLNvnu0tCMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/earnings/index.vue?macro=true";
import { default as editZJoNeHbJptMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/exemptions/[id]/edit.vue?macro=true";
import { default as indexz4sHbrofvmMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/exemptions/[id]/index.vue?macro=true";
import { default as createbIgGYhG61RMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/exemptions/create.vue?macro=true";
import { default as indexd4W4sQVPjQMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/exemptions/index.vue?macro=true";
import { default as indexxj6L6kiKauMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/index.vue?macro=true";
import { default as _91id_93H9tFahXFLoMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/payouts/[id].vue?macro=true";
import { default as indexw89DjJaE9wMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/payouts/index.vue?macro=true";
import { default as _91id_93Jwwu37fNGWMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/receipts/[id].vue?macro=true";
import { default as indexwYQ3tUmk1BMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/receipts/index.vue?macro=true";
import { default as editwAKNtjAEE2Meta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/edit.vue?macro=true";
import { default as indexGnBka5zZ27Meta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/index.vue?macro=true";
import { default as attachSYfNxYdfz1Meta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/rushpay-plans/[planId]/drivers/attach.vue?macro=true";
import { default as indexXyWN7o6RAoMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/rushpay-plans/[planId]/drivers/index.vue?macro=true";
import { default as indexgXAHLw8l2gMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/rushpay-plans/[planId]/index.vue?macro=true";
import { default as createCsLDr97ZNrMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/rushpay-plans/create.vue?macro=true";
import { default as indexgHJVI8WobWMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/rushpay-plans/index.vue?macro=true";
import { default as create45B8NWR8RMMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/create.vue?macro=true";
import { default as editlxWdwz0ESVMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/dispatch-systems/[id]/edit.vue?macro=true";
import { default as index9aUvGBMGJLMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/dispatch-systems/[id]/index.vue?macro=true";
import { default as createrdjgJyJSQjMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/dispatch-systems/create.vue?macro=true";
import { default as indexWNtytOeuGeMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/dispatch-systems/index.vue?macro=true";
import { default as indextWpOhUB4XsMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/index.vue?macro=true";
import { default as editHCbyG8psKWMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/charges/[id]/edit.vue?macro=true";
import { default as indexcFkQ5MAs7xMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/charges/[id]/index.vue?macro=true";
import { default as createjgPKH1kwSEMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/charges/create.vue?macro=true";
import { default as index6jGB8izEr9Meta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/charges/index.vue?macro=true";
import { default as index1bgGyGGFR9Meta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/fleets/[fleetId]/statements/[statementId]/index.vue?macro=true";
import { default as indexHptwXmmtK8Meta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/index.vue?macro=true";
import { default as indexiCzHqoWvSDMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/unallocated/index.vue?macro=true";
import { default as editNPmYKNmF1bMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/weekly-access-fees/[id]/edit.vue?macro=true";
import { default as indexRQV1Kq7zzkMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/weekly-access-fees/[id]/index.vue?macro=true";
import { default as createzjcLsjlR6jMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/weekly-access-fees/create.vue?macro=true";
import { default as indexkTBwxjqTdkMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/weekly-access-fees/index.vue?macro=true";
import { default as login44MzSOuPW6Meta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/login.vue?macro=true";
import { default as editwIfDjrB7XkMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/[id]/edit.vue?macro=true";
import { default as index71pDyssyHFMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/[id]/index.vue?macro=true";
import { default as editXWtQxxKQDHMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/categories/[id]/edit.vue?macro=true";
import { default as indexFRJNmqSJ5dMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/categories/[id]/index.vue?macro=true";
import { default as createxowTmnqvUcMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/categories/create.vue?macro=true";
import { default as indexvLJZZQYqzQMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/categories/index.vue?macro=true";
import { default as createmfOdfEI3EKMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/create.vue?macro=true";
import { default as indexM9GsgPRIC8Meta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/index.vue?macro=true";
import { default as indexY830sbBzOZMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/profile/index.vue?macro=true";
import { default as index2GcVKJwCTRMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/Users/[id]/index.vue?macro=true";
import { default as edityZMAwjnRhnMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/Users/[id]/role/edit.vue?macro=true";
import { default as indexunKDgnqdpWMeta } from "/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/Users/index.vue?macro=true";
export default [
  {
    name: "agreements-id-drivers-attach",
    path: "/agreements/:id()/drivers/attach",
    meta: attach0MoUUgws9kMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/agreements/[id]/drivers/attach.vue")
  },
  {
    name: "agreements-id-drivers",
    path: "/agreements/:id()/drivers",
    meta: indexp9ghZDTcHnMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/agreements/[id]/drivers/index.vue")
  },
  {
    name: "agreements-id-edit",
    path: "/agreements/:id()/edit",
    meta: editdUy4jCKTGAMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/agreements/[id]/edit.vue")
  },
  {
    name: "agreements-id",
    path: "/agreements/:id()",
    meta: indexYfm7WcnzQLMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/agreements/[id]/index.vue")
  },
  {
    name: "agreements-create",
    path: "/agreements/create",
    meta: createGCh35b4F1OMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/agreements/create.vue")
  },
  {
    name: "agreements",
    path: "/agreements",
    meta: index14UpqbIYvYMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/agreements/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboard76aFlOFRSxMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/dashboard.vue")
  },
  {
    name: "documents-id-edit",
    path: "/documents/:id()/edit",
    meta: editVtqzvEHuwoMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/[id]/edit.vue")
  },
  {
    name: "documents-id",
    path: "/documents/:id()",
    meta: indexw6VN6wRfIQMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/[id]/index.vue")
  },
  {
    name: "documents-create",
    path: "/documents/create",
    meta: createj2XTfrhGlmMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/create.vue")
  },
  {
    name: "documents",
    path: "/documents",
    meta: indexKfbMPzF49vMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/index.vue")
  },
  {
    name: "documents-types-id-edit",
    path: "/documents/types/:id()/edit",
    meta: editzTtGnUNzzMMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/types/[id]/edit.vue")
  },
  {
    name: "documents-types-id",
    path: "/documents/types/:id()",
    meta: indexCf5jOIqpTHMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/types/[id]/index.vue")
  },
  {
    name: "documents-types-create",
    path: "/documents/types/create",
    meta: createSMNwTjESIZMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/types/create.vue")
  },
  {
    name: "documents-types",
    path: "/documents/types",
    meta: indexuFCQazNyd5Meta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/types/index.vue")
  },
  {
    name: "documents-vehicle-changes-id-edit",
    path: "/documents/vehicle-changes/:id()/edit",
    meta: editmkZumNiCUAMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/vehicle-changes/[id]/edit.vue")
  },
  {
    name: "documents-vehicle-changes-id",
    path: "/documents/vehicle-changes/:id()",
    meta: index6IcLbKWX9oMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/vehicle-changes/[id]/index.vue")
  },
  {
    name: "documents-vehicle-changes",
    path: "/documents/vehicle-changes",
    meta: indexx2M388QkIvMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/documents/vehicle-changes/index.vue")
  },
  {
    name: "driver-partners-id-edit",
    path: "/driver-partners/:id()/edit",
    meta: editzVdaUHcz0lMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/[id]/edit.vue")
  },
  {
    name: "driver-partners-id",
    path: "/driver-partners/:id()",
    meta: index3F7HEkxhL0Meta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/[id]/index.vue")
  },
  {
    name: "driver-partners-bookings-id",
    path: "/driver-partners/bookings/:id()",
    meta: _91id_93zay7EtI46MMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/bookings/[id].vue")
  },
  {
    name: "driver-partners-bookings",
    path: "/driver-partners/bookings",
    meta: indexEqOXYIiqdiMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/bookings/index.vue")
  },
  {
    name: "driver-partners-earnings-id",
    path: "/driver-partners/earnings/:id()",
    meta: _91id_93dy3Pn2Q9c5Meta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/earnings/[id].vue")
  },
  {
    name: "driver-partners-earnings",
    path: "/driver-partners/earnings",
    meta: indexVRLNvnu0tCMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/earnings/index.vue")
  },
  {
    name: "driver-partners-exemptions-id-edit",
    path: "/driver-partners/exemptions/:id()/edit",
    meta: editZJoNeHbJptMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/exemptions/[id]/edit.vue")
  },
  {
    name: "driver-partners-exemptions-id",
    path: "/driver-partners/exemptions/:id()",
    meta: indexz4sHbrofvmMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/exemptions/[id]/index.vue")
  },
  {
    name: "driver-partners-exemptions-create",
    path: "/driver-partners/exemptions/create",
    meta: createbIgGYhG61RMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/exemptions/create.vue")
  },
  {
    name: "driver-partners-exemptions",
    path: "/driver-partners/exemptions",
    meta: indexd4W4sQVPjQMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/exemptions/index.vue")
  },
  {
    name: "driver-partners",
    path: "/driver-partners",
    meta: indexxj6L6kiKauMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/index.vue")
  },
  {
    name: "driver-partners-payouts-id",
    path: "/driver-partners/payouts/:id()",
    meta: _91id_93H9tFahXFLoMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/payouts/[id].vue")
  },
  {
    name: "driver-partners-payouts",
    path: "/driver-partners/payouts",
    meta: indexw89DjJaE9wMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/payouts/index.vue")
  },
  {
    name: "driver-partners-receipts-id",
    path: "/driver-partners/receipts/:id()",
    meta: _91id_93Jwwu37fNGWMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/receipts/[id].vue")
  },
  {
    name: "driver-partners-receipts",
    path: "/driver-partners/receipts",
    meta: indexwYQ3tUmk1BMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/driver-partners/receipts/index.vue")
  },
  {
    name: "fleets-id-edit",
    path: "/fleets/:id()/edit",
    meta: editwAKNtjAEE2Meta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/edit.vue")
  },
  {
    name: "fleets-id",
    path: "/fleets/:id()",
    meta: indexGnBka5zZ27Meta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/index.vue")
  },
  {
    name: "fleets-id-rushpay-plans-planId-drivers-attach",
    path: "/fleets/:id()/rushpay-plans/:planId()/drivers/attach",
    meta: attachSYfNxYdfz1Meta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/rushpay-plans/[planId]/drivers/attach.vue")
  },
  {
    name: "fleets-id-rushpay-plans-planId-drivers",
    path: "/fleets/:id()/rushpay-plans/:planId()/drivers",
    meta: indexXyWN7o6RAoMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/rushpay-plans/[planId]/drivers/index.vue")
  },
  {
    name: "fleets-id-rushpay-plans-planId",
    path: "/fleets/:id()/rushpay-plans/:planId()",
    meta: indexgXAHLw8l2gMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/rushpay-plans/[planId]/index.vue")
  },
  {
    name: "fleets-id-rushpay-plans-create",
    path: "/fleets/:id()/rushpay-plans/create",
    meta: createCsLDr97ZNrMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/rushpay-plans/create.vue")
  },
  {
    name: "fleets-id-rushpay-plans",
    path: "/fleets/:id()/rushpay-plans",
    meta: indexgHJVI8WobWMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/[id]/rushpay-plans/index.vue")
  },
  {
    name: "fleets-create",
    path: "/fleets/create",
    meta: create45B8NWR8RMMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/create.vue")
  },
  {
    name: "fleets-dispatch-systems-id-edit",
    path: "/fleets/dispatch-systems/:id()/edit",
    meta: editlxWdwz0ESVMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/dispatch-systems/[id]/edit.vue")
  },
  {
    name: "fleets-dispatch-systems-id",
    path: "/fleets/dispatch-systems/:id()",
    meta: index9aUvGBMGJLMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/dispatch-systems/[id]/index.vue")
  },
  {
    name: "fleets-dispatch-systems-create",
    path: "/fleets/dispatch-systems/create",
    meta: createrdjgJyJSQjMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/dispatch-systems/create.vue")
  },
  {
    name: "fleets-dispatch-systems",
    path: "/fleets/dispatch-systems",
    meta: indexWNtytOeuGeMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/dispatch-systems/index.vue")
  },
  {
    name: "fleets",
    path: "/fleets",
    meta: indextWpOhUB4XsMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/fleets/index.vue")
  },
  {
    name: "invoices-charges-id-edit",
    path: "/invoices/charges/:id()/edit",
    meta: editHCbyG8psKWMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/charges/[id]/edit.vue")
  },
  {
    name: "invoices-charges-id",
    path: "/invoices/charges/:id()",
    meta: indexcFkQ5MAs7xMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/charges/[id]/index.vue")
  },
  {
    name: "invoices-charges-create",
    path: "/invoices/charges/create",
    meta: createjgPKH1kwSEMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/charges/create.vue")
  },
  {
    name: "invoices-charges",
    path: "/invoices/charges",
    meta: index6jGB8izEr9Meta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/charges/index.vue")
  },
  {
    name: "invoices-fleets-fleetId-statements-statementId",
    path: "/invoices/fleets/:fleetId()/statements/:statementId()",
    meta: index1bgGyGGFR9Meta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/fleets/[fleetId]/statements/[statementId]/index.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: indexHptwXmmtK8Meta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/index.vue")
  },
  {
    name: "invoices-unallocated",
    path: "/invoices/unallocated",
    meta: indexiCzHqoWvSDMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/unallocated/index.vue")
  },
  {
    name: "invoices-weekly-access-fees-id-edit",
    path: "/invoices/weekly-access-fees/:id()/edit",
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/weekly-access-fees/[id]/edit.vue")
  },
  {
    name: "invoices-weekly-access-fees-id",
    path: "/invoices/weekly-access-fees/:id()",
    meta: indexRQV1Kq7zzkMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/weekly-access-fees/[id]/index.vue")
  },
  {
    name: "invoices-weekly-access-fees-create",
    path: "/invoices/weekly-access-fees/create",
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/weekly-access-fees/create.vue")
  },
  {
    name: "invoices-weekly-access-fees",
    path: "/invoices/weekly-access-fees",
    meta: indexkTBwxjqTdkMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/invoices/weekly-access-fees/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login44MzSOuPW6Meta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/login.vue")
  },
  {
    name: "news-id-edit",
    path: "/news/:id()/edit",
    meta: editwIfDjrB7XkMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/[id]/edit.vue")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    meta: index71pDyssyHFMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/[id]/index.vue")
  },
  {
    name: "news-categories-id-edit",
    path: "/news/categories/:id()/edit",
    meta: editXWtQxxKQDHMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/categories/[id]/edit.vue")
  },
  {
    name: "news-categories-id",
    path: "/news/categories/:id()",
    meta: indexFRJNmqSJ5dMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/categories/[id]/index.vue")
  },
  {
    name: "news-categories-create",
    path: "/news/categories/create",
    meta: createxowTmnqvUcMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/categories/create.vue")
  },
  {
    name: "news-categories",
    path: "/news/categories",
    meta: indexvLJZZQYqzQMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/categories/index.vue")
  },
  {
    name: "news-create",
    path: "/news/create",
    meta: createmfOdfEI3EKMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/create.vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexM9GsgPRIC8Meta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/news/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexY830sbBzOZMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/profile/index.vue")
  },
  {
    name: "Users-id",
    path: "/Users/:id()",
    meta: index2GcVKJwCTRMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/Users/[id]/index.vue")
  },
  {
    name: "Users-id-role-edit",
    path: "/Users/:id()/role/edit",
    meta: edityZMAwjnRhnMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/Users/[id]/role/edit.vue")
  },
  {
    name: "Users",
    path: "/Users",
    meta: indexunKDgnqdpWMeta || {},
    component: () => import("/home/runner/work/staff-portal-frontend/staff-portal-frontend/pages/Users/index.vue")
  }
]